<template>
  <div
    class="fixed inset-0 bg-gray-500 bg-opacity-75 transition transition-opacity z-5000"
  >
    <div class="flex items-center justify-center min-h-full text-center">
      <div
        class="bg-white rounded-lg text-center text-sm overflow-hidden shadow-xl max-w-lg w-full p-6"
      >
        <div class="font-bold text-xl" v-text="title" />
        <div v-if="description" v-text="description" />
        <template v-if="status.type !== 'success_with_report'">
          <div
            class="h-[300px] mt-6 mb-3 relative border-2 border-dashed border-gray-300 rounded-lg"
            :class="{
              'pointer-events-none':
                status.type === 'pending' ||
                status.type === 'processing' ||
                status.type === 'success'
            }"
          >
            <input
              type="file"
              aria-label="file"
              class="absolute inset-0 opacity-0 cursor-pointer w-full"
              :multiple="multiple"
              :accept="accepts"
              @change="dialogChange"
            />
            <div
              class="pointer-events-none flex flex-col items-center text-gray-600 gap-2 justify-center absolute inset-0"
            >
              <i
                v-if="status.type === 'error'"
                class="material-symbols-outlined text-6xl text-red-400"
                aria-hidden="true"
                >error</i
              >
              <i
                v-else-if="status.type === 'success'"
                class="material-symbols-outlined text-6xl"
                aria-hidden="true"
                >done</i
              >
              <i
                v-else-if="status.type === 'pending'"
                class="material-symbols-outlined loading text-6xl"
                aria-hidden="true"
                >progress_activity</i
              >
              <i
                v-else-if="status.type === 'processing'"
                class="material-symbols-outlined loading text-6xl"
                aria-hidden="true"
                >settings</i
              >
              <i
                v-else
                class="material-symbols-outlined text-6xl"
                aria-hidden="true"
                v-text="icon"
              />
              <span v-text="status.text" />
            </div>
          </div>
        </template>
        <template v-else>
          <button
            type="button"
            class="h-[300px] mt-6 mb-3 relative border-2 border-dashed border-gray-300 rounded-lg w-full"
            @click="exportLog"
          >
            <div
              class="pointer-events-none flex flex-col items-center text-gray-600 gap-2 justify-center absolute inset-0"
            >
              <i class="material-symbols-outlined text-6xl" aria-hidden="true"
                >exclamation</i
              >
              <span v-html="status.text" />
            </div>
          </button>
        </template>
        <div class="flex flex-row-reverse justify-between">
          <button
            type="button"
            :disabled="
              status.type === 'pending' ||
              status.type === 'processing' ||
              status.type === 'success'
            "
            class="enabled:cursor-pointer w-1/3 inline-flex justify-center rounded-md border shadow-sm px-4 py-2 text-base font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 sm:text-sm"
            @click="dialogClose"
            v-text="dialogCloseText"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { ref, computed, watch } from 'vue'
import { type AvvUploadParams } from './types'
import Utils from './../utils'
import ASpinner from '../_abstract/ASpinner.vue'

type ResponseData = { error: string } | { report: unknown } | {}

type Status = {
  type:
    | 'pending'
    | 'processing'
    | 'success'
    | 'error'
    | 'idle'
    | 'success_with_report'
  text: string
  data?: ResponseData
}

const localize = (path: string, args?: Record<string, unknown>) =>
  window.localizeText(`avv_upload.${path}`, args)

const props = withDefaults(defineProps<AvvUploadParams>(), {
  icon: 'cloud_upload',
  accept: '*',
  multiple: false
})

const emit = defineEmits<{
  (e: 'change', response: unknown): void
  (e: 'close'): void
}>()

const accepts = computed(() =>
  typeof props.accept === 'string' ? props.accept : props.accept.join(',')
)

const status = ref<Status>({ type: 'idle', text: localize('defaults.hint') })

const dialogCloseText = computed(() => {
  switch (status.value.type) {
    case 'idle':
    case 'error':
    case 'pending':
    case 'processing':
      return localize('buttons.cancel')
    case 'success':
    case 'success_with_report':
      return localize('buttons.close')
  }
})

const dialogClose = () => {
  emit('close')
}

const validateFile = (file: File): string | false => {
  if (file.size >= 256e6) {
    return localize('errors.file_too_big')
  }

  return false
}

const exportLog = () => {
  const blob = new Blob([JSON.stringify(status.value.data.report)], {
    type: 'application/json'
  })
  const url = URL.createObjectURL(blob)

  const link = document.createElement('a')
  link.href = url
  link.download = `import_report_${Date.now()}.json`
  link.click()
  link.remove()

  URL.revokeObjectURL(url)
}

const dialogChange = async (event: Event) => {
  event.preventDefault()
  event.stopPropagation()

  status.value = { type: 'pending', text: localize('status.pending') }

  const target = event.target as HTMLInputElement
  const files = target.files as FileList

  // Validate file size
  for (const file of files) {
    const error = validateFile(file)
    if (error) {
      status.value = {
        type: 'error',
        text: error
      }

      return
    }
  }

  // Construct payload
  const payload = Object.assign(
    { file: props.multiple ? files : files[0] },
    props.data ?? {}
  )

  // Send payload
  const { data: response, status: code } = (await Utils.axios.postForm(
    props.path,
    payload,
    { validateStatus: () => true }
  )) as {
    status: number
    data: ResponseData
  }

  if (response) {
    // Errors might be returned even on 200
    if ('error' in response) {
      status.value = {
        type: 'error',
        text: response.error as string
      }
    } else {
      status.value = {
        type: 'processing',
        text: localize('status.processing'),
        data: response
      }
    }
  } else {
    // Report status
    status.value = {
      type: 'error',
      text: localize('errors.code', { code })
    }
  }
}

const timeout = ref<NodeJS.Timeout>()

watch(status, ({ type }) => {
  if (timeout.value) {
    // Clear any existing timeout
    clearTimeout(timeout.value)

    timeout.value = undefined
  }

  if (type === 'success') {
    // Close automatically after timeout
    timeout.value = setTimeout(() => {
      emit('close')

      timeout.value = undefined
    }, 2000)
  } else if (type === 'processing') {
    const response = status.value.data as { report: unknown } | {}

    setTimeout(async () => {
      if (props.process) {
        await props.process(response)
      }

      if ('report' in response && response.report) {
        status.value = {
          type: 'success_with_report',
          text: localize('status.success_with_report'),
          data: response
        }
      } else {
        status.value = {
          type: 'success',
          text: localize('status.success'),
          data: response
        }
      }

      setTimeout(() => emit('change', response), 0)
    }, 0)
  } else if (type === 'error') {
    // Return back to idle after few seconds
    timeout.value = setTimeout(() => {
      status.value = { type: 'idle', text: localize('defaults.hint') }

      timeout.value = undefined
    }, 5000)
  }
})
</script>
<style lang="scss" scoped>
i.loading {
  -webkit-animation: load3 1.4s infinite linear;
  animation: load3 1.4s infinite linear;
}
</style>
